.body-img-comming-soon {
  width: 80%;
  height: 80%;
  @media (max-width: 900px) {
    width: 60%;
    height: 60%;
  }

  /* @media (max-width: 500px) {
    width: 60%;
    height: 60%;
  } */
}
