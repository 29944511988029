/* 

html,
body {
  height: 100%;
  min-height: -webkit-fill-available;
}
.header_view_full {
    display: flex;
    margin-right: 40px;
}

.header_view_full li {
    position: unset !important;
}

.header_view_min {
    display: none;
}


.header_action-button {
    display: flex;
}

.header-icon-search {
    display: none;
}


@media (max-width: 1013px) {
    .header_view_full {
        display: none;
    }

    .header_view_min {
        display: flex;
    }
    .header_logo {
        align-items: center;
        display: flex;
    }
    .header_action-button {
        display: none;
    }
    .header-icon-search{
        display: flex;
        align-items: center; 
    }
} */



/* 
.header-submenu {
    padding: 0px;
    .ant-menu-sub {
        border-radius: 0;
        .ant-menu-item:hover,
        .ant-menu-item-selected {
            background-color: #A48F44 !important;
            border-radius: 0;
            .ant-menu-title-content {
                color: #ffffff;
            }
        }
    }
}

.list-language .ant-popover-inner {
    padding: 0;
} */


html,
body {
  height: 100%;
  min-height: -webkit-fill-available;
}

.header_view_full {
  display: flex;
  margin-right: 40px;
  /* @media (max-width: 1013px) {
    display: none;
  } */
}

.header_view_full li {
  position: unset !important;
}

.header_view_min {
  display: none;
  /* @media (max-width: 1013px) {
    display: flex;
  } */
}

/* .header_logo {
  @media (max-width: 1013px) {
    align-items: center;
    display: flex;
  }
} */


@media (max-width: 1013px) {
  .header_view_full {
    display: none;
  }

  .header_view_min {
    display: flex;
    display: -webkit-flex;

  }

  .header_logo {
    align-items: center;
    display: flex;

  }

  .header_action-button {
    display: none;
  }

  .header-icon-search {
    display: flex;
    align-items: center;
  }
}

.header_action-button {
  display: flex;
  gap: 8px;
  /* @media (max-width: 1013px) {
    display: none;
  } */
}

.header-icon-search {
  display: none;
  /* @media (max-width: 1013px) {
    display: flex;
    align-items: center;
  } */
}

.header-submenu {
  padding: 0px;
  .ant-menu-sub {
    border-radius: 0;
    .ant-menu-item:hover,
    .ant-menu-item-selected {
      background-color: #a48f44 !important;
      border-radius: 0;
      .ant-menu-title-content {
        color: #ffffff;
      }
    }
  }
}

.list-language .ant-popover-inner {
  padding: 0;
}
